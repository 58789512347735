body,
body * {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body,
html {
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

img {
  max-width: 100%;
}

.standardSelect__control {
  width: 100%;
  min-height: 45px !important;
  cursor: pointer;
}

.standardSelect__menu .standardSelect__option,
.mySelect__menu .mySelect__option {
  cursor: pointer;
  z-index: 100;
  font-size: .9rem;
  /* min-height: 45px; */
}

.w-100 {
  width: 100%;
}

.authCreative {
  max-height: 62vh;
  width: auto;
}

.menuActive {
  background-color: #EDA23E;
  display: block;
  position: relative;
  text-decoration: none;
}

.menuActive svg,
.menuActive svg path {
  fill: #ffffff;
  stroke: #ffffff;
}

.menuActive:after {
  content: '';
  width: 6px;
  height: 57px;
  position: absolute;
  right: -3px;
  top: -2px;
  z-index: 1;
  background: #EDA23E;
  text-decoration: none;
}

.menuActive h6 {
  color: #fff;
  font-size: 1rem;
}

.menuInactive {
  text-decoration: none;
}



.profiletabmenu {
  border: 1px solid;
  background-color: #fff;
  position: relative;
  text-decoration: none;
}

.menuTabActive {
  background-color: #EDA23E;
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
}

.menuTabActive h6 {
  color: #fff;
  font-size: 1rem;
}


.profiletabsList .profiletabmenu:nth-child(2),
.profiletabsList .profiletabmenu:nth-child(3),
.profiletabsList .profiletabmenu:nth-child(4) {
  border-left: none;
}

.profiletabsList .profiletabmenu:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.profiletabsList .profiletabmenu:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pointerCursor {
  cursor: pointer;
}

.removeLogo {
  position: absolute;
  top: 6px;
  right: -38px;
  cursor: pointer;

}

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

.standardSelect__menu {
  z-index: 100 !important
}

.IconClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}

/*report csss */

.logo-right {
  display: flex;
  justify-Content: flex-end;
  border: none
}

.logo-right.top {
  padding: 50px 20px 20px;
}

.logo-right h5 {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: .01px !important;
  text-align: center;
  margin: 10px 0;
}

.logo-right p {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: .01px !important;
  text-align: left;
  margin: 0;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: .01px !important;
}


.left-line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: auto;
  left: auto;
  z-index: 9
}

.bottomleft-line {
  position: absolute;
  top: auto;
  bottom: -4px;
  right: auto;
  left: 0;
  z-index: -1;
}

.content-part,
.bottom-part {
  box-shadow: none !important;
  background-color: transparent !important;
}

.footer-part {
  box-shadow: none !important;
}

.detailedReportWrap h6 {
  font-size: 1.1rem;
}

.content-part p {
  font-size: 38px;
  font-weight: 800;
  line-height: 50px;
  letter-spacing: .01px !important;
  text-align: left;

}

.content-part h5 {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: .01px !important;
  text-align: left;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.content-part h5:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: auto;
  background-color: #EDA23E;
  width: 100px;
  height: 6px;
}

.report .content-part h5:before {
  width: 70px;
  height: 4px;
}

.report .content-part h5 {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.content-part.cover {
  min-width: 275px;
  margin-top: 150px;
}

.pl-80 {
  padding-left: 80px !important
}

.listing-content p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: .01px !important;
  text-align: left;
  margin: 0;
}

.report .listing-content p {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: .01px !important;
  text-align: left;
}

.reportTopHeading {
  display: flex;
}

.reportTopHeading span {
  margin-right: 8px;
}

.report .listing-content p span {
  /* width: 300px; */
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
}

.report .para-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #363636;
  letter-spacing: .01px !important;
  text-align: left;
  font-family: Inter, sans-serif;
}

.detailedReportWrap * {
  text-align: left;
}

.detailedReportWrap table td,
.detailedReportWrap .MuiTypography-body1 {
  font-size: 14px !important;
  line-height: 24px;
}

.bottom-section {
  margin-top: 206px;
  margin-bottom: 65px;
}

.bottom-part p {
  font-size: 25px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: .01px !important;
  text-align: left;
  margin: 0;
}

.detailedReportWrap h5 {
  margin-bottom: 20px;
}

.bottom-part p span {
  display: block;
  font-weight: 700;
}

.bottom-part .listing-content p {
  font-size: 11px;
  font-weight: 500;
  line-height: 2px;
  letter-spacing: .01px !important;
  text-align: left;
}

.bottom-part .listing-content p label {
  font-size: 12px;
  display: block;
  line-height: 22px;
}

.bottom-part .listing-content p span {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: .01px !important;
  text-align: left;
}

.footer-part .listing-content {
  padding: 0px 10px 0px 30px;
}

.footer-part .card-content:last-child {
  padding: 0;
}

.footer-part .listing-content p {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  letter-spacing: .01px !important;
}

.listing-content.cover {
  margin-top: 85px;
}

.footer-part .css-1mb67qp-MuiDivider-root {
  border-color: rgba(255, 255, 255, 1);
}

.footer-part .css-1mb67qp-MuiDivider-root:last-child {
  border-right: none
}

/*calender css*/
.dateRange-Picker {
  /* position: absolute; */
  height: 46px;
  border: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 0 20px;
  background-repeat: no-repeat;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 15px;
  width: 100%;
}

.calanderWrap {
  position: relative;
}

.calanderWrap .rdrCalendarWrapper {
  color: #000000;
  font-size: 15px;
  position: absolute;
  z-index: 9;
  width: 100%;
}

.dateRange-Picker span {
  font-weight: 600;
  font-size: 16px;
}

.dateRange-Picker span.separater {
  height: 27px;
  width: 1px;
  background: #E8E8E8;
}

.rdrCalendarWrapper .rdrMonth {
  width: 100%;
}

/* Modal for mental health scroll */
.modalRowExpanded {
  width: 1700px !important;
}

.timeframeInput {
  width: 60px;
  margin-right: 10px !important;
}

.timeframeRow {
  width: 240px;
}

.timeframeRow .MuiGrid-root.MuiGrid-container {
  width: 100% !important;
  flex-basis: 100% !important;
}

.timeframeRow .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 {
  width: calc(100% - 70px) !important;
  flex-basis: calc(100% - 70px) !important;
  max-width: 80% !important;
}

.modalRowExpanded .MuiGrid-grid-xs-1:last-of-type {
  margin-left: auto;
  max-width: 190px !important;
  flex-grow: 1;
}

.modalRowExpandeds {
  width: 2400px !important;
  flex-wrap: nowrap !important;
}

.substanceRowExtended {
  width: 400px !important;
}

.substanceRow {
  width: 300px !important;
}

div#pdfprint {
  margin: 0 auto;
}

table tr th,
table tr td {

  font-family: 'Inter', sans-serif !important
}

.m-auto {
  margin: auto;
}

.container {
  /* display: block; */
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: none;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #079F00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

hr.divider-mt-2 {
  margin-top: 20px;
}

.reportTopContainer {
  max-width: 100%;
  width: 860px;
  text-align: right;
  margin: 20px auto
}

.casenoteTopContainer {
  max-width: 100%;
  width: 1000px;
  text-align: left;
  margin: 20px auto
}

.casenoteExportTopContainer {
  max-width: 100%;
  width: 1500px;
  text-align: left;
  margin: 20px auto
}

.reportContainer {
  max-width: 100%;
  width: 860px;
  text-align: center;
  margin: 20px auto;
  border: 1px solid #f7f4f4;
}

.detailedReportWrap table tr th {
  font-size: 13px !important;
  line-height: 19px;
}

.detailedReportWrap * {
  word-break: normal !important;
}

.inputTypography {
  border-bottom: 1px solid;
  padding-bottom: 4px;
  min-height: 23px;
}

.logo-right .comprehensiveSubtitle {
  font-size: 18px;
  line-height: 29px;
  display: block;
}
.space-bar.h-10{
  height:10px;
}