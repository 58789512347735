.uploadDocumentBody {
  margin-top: 4px;
  border: 1px solid #ded2d9;
  height: 45px;
  justify-content: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
}

.browse-link {
  text-decoration: underline;
}

.downloadFile {
  margin-right: 9px;
}

.uploadFile {
  margin-top: 8px;
  padding: 7px 15px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploadFile {
  margin-top: 8px;
  padding: 7px 15px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploadFile_download {
  margin-top: 8px;
  padding: 7px 15px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploadFile .removeUploadedFile {
  margin-left: 10px;
  width: 23px;
}

.uploadedFileName {
  font-size: 13px;
  font-weight: 600;
}

.uploadedFileSize {
  color: #0000007d;
  font-size: 12px;
  font-weight: 600;
}

.removeUploadedFile {
  all: unset;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  margin-left: 15px;
}

.uploadDocuments {
  margin-top: 15px;
}

.browseFileIcon {
  background-color: #f4eee6;
  border-radius: 50%;
}
.filesList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
